import React from 'react'
import { PageProps } from 'gatsby'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'

export interface BannerProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location: PageProps['location']
}

interface BannersProps {
  [key: string]: React.FC<BannerProps>
}

const BannerShell: React.FC<BannerProps> = ({ fields, location }) => {
  const banners: BannersProps = {
    default: BannerDefault,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
