import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface PostContextProps {
  exists: boolean
  title: string
  translations: []
  locale: {
    locale: string
  }
  languages: []
}

export const PostContext = React.createContext({
  exists: false,
  title: '',
  translations: [],
  locale: {
    locale: '',
  },
  languages: [],
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    site: {
      languages: []
    }
    page: {
      databaseId: number
      title?: string
      path?: string
      seo: SeoProps
      flexContent: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment>
      translations?: any
      locale: {
        locale: string
      }
    }
  }
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    site,
    page: { title = '', flexContent, seo, translations, locale },
  },
  location,
  prefix = 'post_Flexcontent',
}) => (
  <PostContext.Provider
    value={{
      exists: true,
      title,
      translations,
      locale,
      languages: typeof site !== 'undefined' ? site.languages : [],
    }}
  >
    <Layout>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
  </PostContext.Provider>
)

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
