/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <motion.li
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          key={index}
          className="relative px-10"
        >
          <div className="flex flex-wrap items-center">
            <NavLink
              className="text-xl font-bold text-primary hover:opacity-70 lg:text-lg lg:font-light"
              to={l?.link?.url || '/'}
            >
              {l?.link?.title}
            </NavLink>
            {l?.submenu && (
              <div
                onClick={
                  open === index ? () => setOpen(-1) : () => setOpen(index)
                }
                className="ml-[15px]"
              >
                <Chevron
                  className="transition-all"
                  style={
                    open === index
                      ? {
                          transform: 'rotate(180deg)',
                        }
                      : {
                          transform: 'rotate(0deg)',
                        }
                  }
                />
              </div>
            )}
          </div>
          {l?.submenu && open === index && (
            <AnimatePresence>
              <Submenu fields={l.submenu} />
            </AnimatePresence>
          )}
        </motion.li>
      ))}
    </>
  )
}

export default Menu
