import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const BannerDefault: React.FC<BannerProps> = ({ fields }) => (
  <section id="BannerDefault">
    <div className="container">
      <div className="relative h-[400px]">
        <Plaatjie
          className="!absolute top-0 left-0 -z-10 h-full w-full rounded-2xl"
          alt="banner"
          image={fields?.image}
        />

        <div className="p-10">
          <ParseContent
            className="text-light"
            content={fields.description || ''}
          />
        </div>
      </div>
    </div>
  </section>
)

export default BannerDefault
