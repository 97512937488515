/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import Button from 'components/elements/Button'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <nav
      className="fixed left-0 top-0 right-0 z-10 h-[60px] bg-light transition-all"
      style={
        isSticky
          ? {
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            }
          : {}
      }
    >
      <div className="container flex h-full items-center justify-between">
        <Button aria-label="Home" className="pl-5" to="/">
          <Logo className="h-[35px]" />
        </Button>

        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <Desktop fields={fields} isSticky={isSticky} />
      </div>
    </nav>
  )
}

export default Header
