import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="block lg:hidden">
      <button
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        aria-label="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
      </button>
      {isOpen && (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-primary">
          <div
            className="absolute right-5 top-5 text-center"
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
            aria-label="Close menu"
          >
            <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-light before:[content:''] after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-light after:[content:'']" />
          </div>
          <ul className="m-0 w-8/12 pl-0">
            <Menu fields={fields} />
          </ul>
        </div>
      )}
    </div>
  )
}

export default Mobile
