import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Text: React.FC<TextProps> = ({ fields }) => {
  return (
    <section id="Text">
      <div className="container py-5">
        <ParseContent content={fields.description || ''} />
      </div>
    </section>
  )
}

export default Text
