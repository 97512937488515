import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Button'

interface BlogGridPostProps {
  key: string
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  key,
  node,
  blogFields,
}) => (
  <div
    key={key}
    className="flex h-full flex-col justify-between rounded-2xl bg-slate-100 p-5"
  >
    <div>
      <h2 className="text-xl font-bold">{node.title}</h2>
      <ParseContent
        className="z-10 h-[75px] max-w-full overflow-hidden line-clamp-3"
        content={node.recap?.excerpt || ''}
      />
    </div>
    <div className="mt-8">
      <Button
        className="rounded-lg bg-primary px-6 py-2 font-bold text-light hover:bg-secondary hover:transition-all"
        to={node.uri || '/'}
      >
        {blogFields.readmoretext}
      </Button>
    </div>
  </div>
)

export default BlogGridPost
