import React from 'react'

const Loading: React.FC = () => (
  <div className="bg-opacity-50 bg-light absolute left-0 right-0 bottom-0 top-0 flex cursor-progress">
    <div className="h-[60px] w-[60px] m-auto block relative transition-all">
      <div className="w-[60px] h-[60px] relative animation-spinner-base">
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-1.1s] before:[animation-delay:-1.1s]" />
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-1.0s] before:[animation-delay:-1.0s]" />
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.9s] before:[animation-delay:-0.9s]" />
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.8s] before:[animation-delay:-0.8s]" />
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.7s] before:[animation-delay:-0.7s]" />
        <div className="before:[content:''] before:block before:w-1/4 before:h-1/4 before:bg-secondary before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] rounded-full w-full h-full absolute left-0 top-0 [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.6s] before:[animation-delay:-0.6s]" />
      </div>
    </div>
  </div>
)

export default Loading
