import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Button from 'components/elements/Button'

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <div className="container text-light">
      <div className="rounded-t-3xl bg-primary p-10">
        <h2 className="pb-3 text-2xl font-bold">Footer</h2>
        <ul>
          {fields?.footer?.menuFooter?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className="py-1" key={index}>
              <Button className="hover:underline" to={item?.link?.url || '/'}>
                {item?.link?.title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Footer
