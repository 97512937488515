import { useContext } from 'react'
import { PageContext, PageContextProps } from '../templates/page'
import { PostContext, PostContextProps } from '../templates/post'

export type { PageContextProps, PostContextProps }

const usePage = (): PostContextProps | PageContextProps => {
  const postContext = useContext(PostContext)

  if (postContext.exists) {
    return postContext as PostContextProps
  }

  const pageContext = useContext(PageContext)

  return pageContext as PageContextProps
}

export default usePage
