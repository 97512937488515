import React from 'react'
import { GatsbyLinkProps, Link } from 'gatsby'

export interface CustomGatsbyLinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  isAnchor?: boolean
  isCustom?: boolean
}

const Button: React.FC<CustomGatsbyLinkProps> = ({
  children,
  isCustom = false,
  ...props
}) => {
  let isAnchorNew = props.isAnchor

  if (isCustom) {
    return (
      <div onClick={() => props.onClick} className={props.className}>
        {children}
      </div>
    )
  }

  if (
    props.to.indexOf('tel:') !== -1 ||
    props.to.indexOf('mailto:') !== -1 ||
    props.to.indexOf('/static/') !== -1 ||
    props.to.indexOf('https://') !== -1 ||
    props.to.indexOf('whatsapp:') !== -1
  ) {
    isAnchorNew = true
  }

  return !isAnchorNew ? (
    <Link {...props}>{children}</Link>
  ) : (
    <a href={props.to} {...props}>
      {children}
    </a>
  )
}

export default Button
