import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

// CSS
import 'styles/formstyle.css'

const FormContact: React.FC<FormProps> = ({ fields }) => (
  <section id="FormContact">
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
      <FormDuuf
        id={1}
        className="mt-5"
        generate={false}
        captchaSiteKey="6LcTtE4eAAAAAOYrWnyZTzqgx1EYCrmK9VfX6-_w"
      >
        <div className="grid grid-cols-12 items-start justify-center gap-8">
          <div className="col-span-2" />
          <div className="col-span-4">
            <FormDuufGroup hideIds={[2, 5, 3, 8]} />
          </div>
          <div className="col-span-4">
            <FormDuufGroup showIds={[5, 2, 3, 8]} />
            <FormDuufSubmit />
          </div>
        </div>
      </FormDuuf>
    </div>
  </section>
)

export default FormContact
