import React, { useState, useEffect } from 'react'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'styles/all.css'

export interface LayoutContextProps {
  locale: string
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  setIsScrollable: (bool: any) => bool,
})

interface LayoutProps {
  children: React.ReactNode
  locale?: string
}

const Layout: React.FC<LayoutProps> = ({ children, locale = 'nl_NL' }) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <div
      className={`${
        isScrollable ? `h-full overflow-auto` : `h-screen overflow-hidden`
      }`}
    >
      <LayoutContext.Provider value={{ setIsScrollable, locale }}>
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <div className="min-h-screen pt-[60px]">{children}</div>
        <Footer />
      </LayoutContext.Provider>
    </div>
  )
}

export default Layout
