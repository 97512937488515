/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import { motion } from 'framer-motion'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Submenu: React.FC<SubmenuProps> = function ({ fields }) {
  return (
    <motion.ul
      className="relative w-full overflow-hidden lg:absolute"
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
    >
      <div className="lg:pt-3">
        <div className="py-1 pr-2 pl-3 lg:rounded-md lg:bg-light lg:p-4 lg:shadow-2xl">
          {fields.map((f, index) => (
            <li className="list-none hover:opacity-70" key={index}>
              <NavLink
                className="text-light lg:text-primary"
                to={f?.link?.url || '/'}
              >
                {f?.link?.title}
              </NavLink>
            </li>
          ))}
        </div>
      </div>
    </motion.ul>
  )
}

export default Submenu
